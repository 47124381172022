import React from "react";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../../assets/appstore/1.png";
import img2 from "../../../assets/appstore/2.svg";

const Footer = () => {
  const navigate = useNavigate();
  const links = [
    {
      title: "About",
      path: "/about-us",
    },
    {
      title: "Terms of Service",
      path: "/termsandconditions",
    },
    {
      title: "Privacy Policy",
      path: "/privacyPolicy",
    },
    {
      title: "Refund Policy",
      path: "/refund-policy",
    },
    {
      title: "Contact Us",
      path: "/contact-us",
    },
  ];
  return (
    <div className="flex flex-col justify-between gap-6 bg-secondary3  py-6 px-10 md:flex-row md:items-center">
      <div className="flex flex-col gap-6 md:flex-row">
        {links.map((link, index) => (
          <div
            onClick={() => navigate(link.path)}
            key={index}
            className="cursor-pointer text-[13px]  text-text hover:text-white"
          >
            {link.title}
          </div>
        ))}
      </div>
      <div className="flex gap-6">
        <img src={img1} alt="appstore" className="h-10 md:h-8" />
        <img src={img2} alt="appstore" className="h-10 md:h-8" />
      </div>
      <Link
        to="https://play.google.com/store/apps/details?id=com.alt.alt_premiere"
        target="_blank"
        className="h-30 w-30 fixed bottom-5 right-5 z-20 flex cursor-pointer items-center justify-center rounded-full bg-primary-100 px-4 py-2  text-[13px]  text-white hover:bg-primary-100/70"
      >
        <div className="text-base">Download App</div>
      </Link>
    </div>
  );
};

export default Footer;

import { AnimatePresence, motion } from "framer-motion";
import { Play } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

// YouTube trailer component has been integrated into the HoverCard component

const HoverCard = ({
  item,
  cardRef,
  onMouseEnter,
  onMouseLeave,
  imageType = "horizontal",
  showTrailer = false,
  onCloseTrailer,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const isHorizontal = imageType.toLowerCase() === "horizontal";

  const updatePosition = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const cardWidth = isHorizontal ? 400 : 358;
      let xPos = rect.left - (isHorizontal ? 20 : 29);

      // Prevent going off screen
      if (xPos < 0) xPos = 0;
      if (xPos + cardWidth > windowWidth) xPos = windowWidth - cardWidth;

      setPosition({
        x: xPos,
        y: rect.top - (isHorizontal ? 90 : 10),
      });
    }
  };

  useEffect(() => {
    updatePosition();
    const handleScroll = () => requestAnimationFrame(updatePosition);
    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", updatePosition);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updatePosition);
    };
  }, [cardRef, isHorizontal]);

  return createPortal(
    <motion.div
      className="fixed z-[9999]"
      style={{
        width: isHorizontal ? "400px" : "358px",
        left: position.x,
        top: position.y,
      }}
      initial={{ opacity: 0, scale: 0.7 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.2 }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="overflow-hidden rounded-lg bg-gray-900 shadow-xl">
        <div className={`relative h-52 w-full`}>
          {showTrailer ? (
            <div className="relative h-full w-full">
              <ReactPlayer
                url={item?.trailer}
                playing={true}
                muted={false}
                controls={false}
                width="100%"
                height="100%"
              />
            </div>
          ) : (
            <>
              <img
                src={item.horizontalBanner}
                alt={item.name}
                className="h-full w-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/60 to-transparent" />
            </>
          )}
        </div>

        <div className="px-4 py-2">
          <h3 className="text-lg font-bold text-white">{item?.name}</h3>

          <div className="mt-2 flex flex-wrap items-center gap-2 text-sm text-white/90">
            {item?.type === "Upcoming" ? (
              <span className="rounded-md bg-white/20 px-2 py-1 font-medium">
                Upcoming
              </span>
            ) : (
              <span className="rounded-md bg-white/20 px-2 py-1 font-medium">
                {item?.ageLimit}
              </span>
            )}

            {item?.type === "Movie" && (
              <>
                <span>•</span>
                <span>{item?.duration}</span>
              </>
            )}
            <span>•</span>
            <span>{item?.language}</span>
          </div>

          <p className="line-clamp-2 mt-3 text-sm text-white/80">
            {item?.description ||
              "A retiree takes up a job as a storyteller for an insomniac. What happens when he discovers that his tales are published under a pen name and have won accolades?"}
          </p>

          <div className="mt-4 flex gap-3">
            <Link
              to={
                item?.type === "Upcoming"
                  ? `/upcoming/${item?.slug}`
                  : item?.type === "Single"
                  ? `/movie/${item?.slug}`
                  : `/series/${item?.slug}`
              }
              className="w-full"
            >
              <motion.button
                whileHover={{ backgroundColor: "rgb(255, 255, 255)" }}
                whileTap={{ scale: 0.98 }}
                className="flex w-full flex-1 items-center justify-center gap-2 rounded-lg bg-white py-2 text-sm font-semibold text-black transition-all hover:shadow-lg"
              >
                <Play className="h-4 w-4" fill="currentColor" />
                {item?.type === "Upcoming" ? "Watch Trailer" : "Watch Now"}
              </motion.button>
            </Link>
          </div>
        </div>
      </div>
    </motion.div>,
    document.body
  );
};

const PictureCard = ({ item, index, imageType = "horizontal", onClick }) => {
  // console.log(item);  // Commented out to reduce console noise

  const [isHovered, setIsHovered] = useState(false);
  const [shouldShowHoverCard, setShouldShowHoverCard] = useState(false);
  const [showTrailer, setShowTrailer] = useState(false);
  const hoverTimeoutRef = useRef(null);
  const showCardTimeoutRef = useRef(null);
  const showImageEffectTimeoutRef = useRef(null);
  const cardRef = useRef(null);
  const isHorizontal = imageType.toLowerCase() === "horizontal";
  const HOVER_DELAY = 500; // 500ms delay before showing effects

  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    // Set timeout for image hover effect
    showImageEffectTimeoutRef.current = setTimeout(() => {
      setIsHovered(true);
    }, HOVER_DELAY);

    // Set timeout to show hover card and trailer after 500ms
    showCardTimeoutRef.current = setTimeout(() => {
      setShouldShowHoverCard(true);
      setShowTrailer(true);
    }, HOVER_DELAY);
  };

  const handleMouseLeave = () => {
    // Clear all timeouts
    if (showCardTimeoutRef.current) {
      clearTimeout(showCardTimeoutRef.current);
    }
    if (showImageEffectTimeoutRef.current) {
      clearTimeout(showImageEffectTimeoutRef.current);
    }

    // Add a small delay before closing to allow mouse movement between card and hover card
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovered(false);
      setShouldShowHoverCard(false);
      setShowTrailer(false);
    }, 150);
  };

  const closeTrailer = (e) => {
    e.stopPropagation();
    setShowTrailer(false);
  };

  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
      if (showCardTimeoutRef.current) {
        clearTimeout(showCardTimeoutRef.current);
      }
      if (showImageEffectTimeoutRef.current) {
        clearTimeout(showImageEffectTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="group relative isolate w-full">
      <div
        ref={cardRef}
        className="relative cursor-pointer overflow-hidden rounded-lg bg-gray-900 shadow-lg"
        style={{
          aspectRatio: isHorizontal ? "16/9" : "2/3",
          position: "relative", // Ensure position is relative for absolute positioning
        }}
        onClick={() => {
          if (!showTrailer) {
            onClick?.(item, index);
          }
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <motion.img
          src={isHorizontal ? item?.horizontalBanner : item?.verticalBanner}
          alt={item?.name}
          className="h-full w-full object-cover"
          animate={{
            scale: isHovered ? 1.4 : 1,
            filter: isHovered ? "brightness(0.3)" : "brightness(1)",
          }}
          transition={{
            scale: { type: "spring", stiffness: 400, damping: 25 },
          }}
        />
      </div>

      <AnimatePresence>
        {shouldShowHoverCard && (
          <HoverCard
            item={item}
            cardRef={cardRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            imageType={imageType}
            showTrailer={showTrailer}
            onCloseTrailer={closeTrailer}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default PictureCard;

import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsFillPlayFill } from "react-icons/bs";
import { TbDeviceWatchOff, TbDeviceWatchStats } from "react-icons/tb";

const MediaInfo = ({
  media,
  access,
  watchlistStatus,
  onWatchClick,
  onWatchlistClick,
  isAuthenticated,
  watchlistLoading,
}) => {
  return (
    <div className="space-y-6">
      {/* Title and Actions Bar */}
      <div className="flex flex-col justify-between gap-4 rounded-xl bg-gradient-to-r from-primary-100/20 to-transparent p-4 md:flex-row md:items-center">
        <h1 className="text-2xl font-bold text-white">{media?.name}</h1>
        <div className="flex flex-wrap gap-4">
          {!access && (
            <button
              onClick={onWatchClick}
              className="flex items-center gap-2 rounded-lg bg-amber-500 px-6 py-2.5 text-sm font-semibold text-white transition-all hover:bg-amber-600 hover:shadow-lg"
            >
              <BsFillPlayFill className="text-xl" />
              Watch Now
            </button>
          )}
          {isAuthenticated && (
            <button
              className="flex items-center gap-2 rounded-lg border border-primary-100/30 px-6 py-2.5 text-sm text-white transition-all hover:bg-primary-100/10"
              onClick={() => onWatchlistClick(!watchlistStatus)}
              disabled={watchlistLoading}
            >
              {watchlistStatus ? <TbDeviceWatchOff /> : <TbDeviceWatchStats />}
              {watchlistStatus ? "Remove from Watchlist" : "Add to Watchlist"}
            </button>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 text-white md:grid-cols-2">
        <div className="space-y-4">
          <div className="flex flex-wrap gap-3 text-sm">
            {media?.genres?.map((genre) => (
              <span
                key={genre._id}
                className="rounded-full bg-primary-100/20 px-3 py-1"
              >
                {genre.name}
              </span>
            ))}
          </div>
          <div className="flex gap-4 text-sm text-gray-300">
            <span>{media?.duration}</span>
            <span>•</span>
            <span>{media?.language}</span>
            {media?.releaseDate && (
              <>
                <span>•</span>
                <span>{new Date(media?.releaseDate).getFullYear()}</span>
              </>
            )}
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex gap-1">
            {Array.from({ length: 5 }).map((_, index) => (
              <span key={index}>
                {+media?.rating > index ? (
                  <AiFillStar className="text-xl text-amber-400" />
                ) : (
                  <AiOutlineStar className="text-xl text-gray-500" />
                )}
              </span>
            ))}
          </div>
          <p className="text-sm text-gray-300">Age Limit: {media?.ageLimit}</p>
        </div>
      </div>

      {/* Description */}
      {media?.description && (
        <div className="space-y-2">
          <h2 className="text-lg font-semibold text-white">Description</h2>
          <p className="leading-relaxed text-gray-300">{media?.description}</p>
        </div>
      )}
    </div>
  );
};

export default MediaInfo;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/commen/Loading";
import MovieModal from "../components/MovieModal";
import CastSection from "../components/VideoCards/CastSection";
import MediaInfo from "../components/VideoCards/MediaInfo";
import MediaPlayer from "../components/VideoCards/MediaPlayer";
import SimilarMedia from "../components/VideoCards/SimilarMedia";
import {
  addMovieView,
  addToWatchList,
  checkUserWatchlist,
  fetchMovieDetails,
  getSimilarMovies,
  removeFromWatchList,
  rentAMovie,
} from "../utils/apis";
import useStore from "../utils/store";

const MovieDetails = () => {
  const { id: slug } = useParams();
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const [watch, setWatch] = React.useState(false);
  const queryClient = useQueryClient();
  const [hasTrackedView, setHasTrackedView] = React.useState(false);

  const {
    data: movieData,
    isLoading,
    error,
    refetch: movieRefetch,
  } = useQuery({
    queryKey: ["movieDetails", slug],
    queryFn: () => fetchMovieDetails(slug, user?.token),
    enabled: !!slug,
  });

  const { data: similarMovies } = useQuery({
    queryKey: ["similarMovies", movieData?._id],
    queryFn: () => getSimilarMovies(movieData?._id),
    enabled: !!movieData?._id,
    select: (data) => data[1] || [],
  });

  const { data: watchlistStatus } = useQuery({
    queryKey: ["watchlist", movieData?._id],
    queryFn: () => checkUserWatchlist(user?.token, movieData?._id),
    enabled: !!user?.token && !!movieData?._id,
    select: (data) => data[1]?.isExist,
  });

  const watchlistMutation = useMutation({
    mutationFn: (isAdding) =>
      isAdding
        ? addToWatchList(user.token, {
            id: movieData?._id,
            type: "movie",
          })
        : removeFromWatchList(user.token, {
            id: movieData?._id,
            type: "movie",
          }),
    onSuccess: () => {
      queryClient.invalidateQueries(["watchlist", movieData?._id]);
    },
  });

  const rentMutation = useMutation({
    mutationFn: () =>
      rentAMovie(user?.token, {
        type: "Movie",
        movieId: movieData?._id,
      }),
    onSuccess: () => {
      setWatch(false);
      movieRefetch();
    },
  });

  const handleWatchlist = (isAdding) => {
    if (!user?.token || !movieData?._id) return;
    watchlistMutation.mutate(isAdding);
  };

  const handleWatchClick = () => {
    if (!user) {
      navigate("/login");
      return;
    }

    if (movieData?.oldSubscriptionValid) {
      setWatch(true);
    } else {
      navigate("/subscription");
    }
  };

  // Track view when component mounts and movie data is loaded
  React.useEffect(() => {
    if (movieData?._id && user?.token && !hasTrackedView) {
      addMovieView(user.token, movieData?._id);
      setHasTrackedView(true);
    }
  }, [movieData?._id, user?.token, hasTrackedView]);

  if (isLoading) return <Loading />;
  if (error) {
    return (
      <div className="flex min-h-[100dvh] items-center justify-center text-white">
        Failed to load movie details
      </div>
    );
  }

  const { access, time } = movieData;

  return (
    <div className="min-h-[100dvh] bg-black px-4 pt-10 md:px-10">
      <div className="flex flex-col gap-6 md:flex-row">
        {/* Main Content Section */}
        <div className="w-full space-y-6 md:w-[70%]">
          {/* Video/Image Player Section */}
          <div className="relative aspect-video overflow-hidden rounded-xl shadow-2xl">
            <MediaPlayer
              media={movieData}
              access={access}
              time={time}
              onPlay={handleWatchClick}
            />
          </div>

          {/* Movie Info Section */}
          <MediaInfo
            media={movieData}
            access={access}
            watchlistStatus={watchlistStatus}
            onWatchClick={handleWatchClick}
            onWatchlistClick={handleWatchlist}
            isAuthenticated={!!user}
            watchlistLoading={watchlistMutation.isPending}
          />
        </div>

        {/* Similar Movies Section */}
        <SimilarMedia
          title="Similar Movies"
          data={similarMovies}
          onItemClick={(item) => navigate(`/movie/${item?.slug}`)}
          showInGrid={true}
        />
      </div>

      {/* Cast Section */}
      <CastSection cast={movieData?.cast} />

      {/* Similar Movies (Mobile View) */}
      <SimilarMedia
        title="You might also like"
        data={similarMovies}
        onItemClick={(item) => navigate(`/movie/${item?.slug}`)}
        showInGrid={false}
      />

      {/* Modal */}
      {watch && (
        <MovieModal
          data={movieData}
          setWatch={setWatch}
          RentHandler={() => rentMutation.mutate()}
          access={access}
        />
      )}
    </div>
  );
};

export default MovieDetails;

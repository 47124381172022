import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsFillPlayFill } from "react-icons/bs";
import { TbDeviceWatchOff, TbDeviceWatchStats } from "react-icons/tb";

const SeriesInfo = ({
  series,
  selectedEpisode,
  access,
  handleWatchClick,
  handleWatchlist,
  watchlistData,
  watchlistMutation,
  user,
}) => {
  return (
    <div className="space-y-6">
      {/* Title and Actions Bar */}
      <div className="flex flex-col justify-between gap-4 rounded-xl bg-gradient-to-r from-primary-100/20 to-transparent p-4 md:flex-row md:items-center">
        <div>
          <h1 className="text-2xl font-bold text-white">
            {series?.name}
            {selectedEpisode && (
              <span className="ml-2 text-lg font-normal text-gray-400">
                {selectedEpisode?.name}
              </span>
            )}
          </h1>
        </div>
        <div className="flex flex-wrap gap-4">
          {!access && (
            <button
              onClick={handleWatchClick}
              className="flex items-center gap-2 rounded-lg bg-amber-500 px-6 py-2.5 text-sm font-semibold text-white transition-all hover:bg-amber-600 hover:shadow-lg"
            >
              <BsFillPlayFill className="text-xl" />
              Watch Now
            </button>
          )}
          {user && (
            <button
              className="flex items-center gap-2 rounded-lg border border-primary-100/30 px-6 py-2.5 text-sm text-white transition-all hover:bg-primary-100/10"
              onClick={() => handleWatchlist(!watchlistData)}
              disabled={watchlistMutation.isLoading}
            >
              {watchlistData ? <TbDeviceWatchOff /> : <TbDeviceWatchStats />}
              {watchlistData ? "Remove from Watchlist" : "Add to Watchlist"}
            </button>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 text-white md:grid-cols-2">
        <div className="space-y-4">
          <div className="flex flex-wrap gap-3 text-sm">
            {series?.genres?.map((genre) => (
              <span
                key={genre._id}
                className="rounded-full bg-primary-100/20 px-3 py-1"
              >
                {genre.name}
              </span>
            ))}
          </div>
          <div className="flex gap-4 text-sm text-gray-300">
            <span>{series?.language}</span>
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex gap-1">
            {Array.from({ length: 5 }).map((_, index) => (
              <span key={index}>
                {+series?.rating > index ? (
                  <AiFillStar className="text-xl text-amber-400" />
                ) : (
                  <AiOutlineStar className="text-xl text-gray-500" />
                )}
              </span>
            ))}
          </div>
          <p className="text-sm text-gray-300">Age Limit: {series?.ageLimit}</p>
        </div>
      </div>
    </div>
  );
};

export default SeriesInfo;

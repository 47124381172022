import React from "react";
import PictureCard from "./PictureCard";
import VideoCard from "./VideoCard";

const SimilarMedia = ({ title, data, onItemClick, showInGrid = true }) => {
  if (!data?.length) return null;

  if (showInGrid) {
    return (
      <div className="hidden w-full md:block md:w-[30%]">
        <h2 className="mb-4 text-lg font-semibold text-white">{title}</h2>
        <div className="grid grid-cols-2 gap-4">
          {data?.map((item) => (
            <div
              key={item._id}
              className="transform transition-transform hover:scale-105"
            >
              <PictureCard
                imageType="Vertical"
                item={item}
                onClick={() => onItemClick(item)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="py-8">
      <VideoCard
        className={"px-0"}
        data={{
          name: title,
          imageType: "Vertical",
          content: data?.map((item) => ({
            kind: "movie",
            item: item,
          })),
        }}
      />
    </div>
  );
};

export default SimilarMedia;

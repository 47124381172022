import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "../utils/apis";

export const useSeriesDetails = (slug, token) => {
  return useQuery({
    queryKey: ["series", slug],
    queryFn: async () => {
      const response = await axiosInstance.get(`/user/series/${slug}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    },
    enabled: !!slug,
  });
};

export const useSeasonEpisodes = (seasonSlug, token) => {
  return useQuery({
    queryKey: ["season-episodes", seasonSlug],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/user/season-episodes/${seasonSlug}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    },
    enabled: !!seasonSlug,
  });
};

export const useEpisodeDetails = (episodeSlug, token) => {
  return useQuery({
    queryKey: ["episode-details", episodeSlug],
    queryFn: async () => {
      const response = await axiosInstance.get(`/user/episode/${episodeSlug}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    },
    enabled: !!episodeSlug,
  });
};

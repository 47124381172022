import React from "react";
import ReactPlayer from "react-player/youtube";
import VideoPlayer from "../VideoCards/VideoPlayer";

const SeriesPlayer = ({
  access,
  series,
  episode,
  onPlay,
  onEnded,
  playerRef,
}) => {
  const handlePlay = () => {
    if (onPlay) {
      onPlay();
    }
  };

  if (!access && !series?.trailer && series?.trailer === undefined) {
    return (
      <div className="group relative h-full w-full cursor-pointer">
        <img
          src={series?.horizontalBanner}
          alt={series?.name}
          className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
      </div>
    );
  }

  if (access) {
    if (episode?.url?.includes("www.youtube.com")) {
      return (
        <ReactPlayer
          url={episode?.url}
          controls={true}
          playing={false}
          width="100%"
          height="100%"
          className="overflow-hidden rounded-xl"
          onPlay={handlePlay}
          onEnded={onEnded}
          ref={playerRef}
        />
      );
    }
    return (
      <VideoPlayer
        id={episode?._id}
        hlsSource={episode?.url}
        autoPlay={false}
        poster={series?.horizontalBanner}
        videoSource={episode?.url}
        isEpisode={true}
        selectorId="episode-video"
        startTime={series?.time}
        onPlay={handlePlay}
        onEnded={onEnded}
        playerRef={playerRef}
      />
    );
  }

  return (
    <VideoPlayer
      hlsSource={series?.trailer}
      autoPlay={false}
      poster={series?.horizontalBanner}
      videoSource={series?.trailer}
      selectorId="episode-video"
      onPlay={handlePlay}
      playerRef={playerRef}
    />
  );
};

export default SeriesPlayer;

import { Route, Routes } from "react-router-dom";
import Layout from "./components/commen/Layout/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "swiper/css";
import AllSubscrition from "./pages/AllSubscrition";
import Category from "./pages/Category";
import MovieDetails from "./pages/MovieDetails";
import SearchMobile from "./pages/Search";
import SeriesDetails from "./pages/SeriesDetails";
import ViewAll from "./pages/ViewAll";
import WatchList from "./pages/WatchList";
import Account from "./pages/profile/Account";
import Rented from "./pages/profile/Rented";
import SubscriptionHistory from "./pages/profile/SubsHistory";
import Subscription from "./pages/profile/Subscription";
import About from "./pages/static/About";
import Privacy from "./pages/static/Privacy";
import RefundPolicy from "./pages/static/Refund";
import Return from "./pages/static/Return";
import Terms from "./pages/static/Terms";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Routes>
          <Route
            path="/my-account/subscribe/update"
            element={
              <Layout>
                <Subscription />
              </Layout>
            }
          />
          <Route
            path="/my-account/subscriptions-history"
            element={
              <Layout>
                <SubscriptionHistory />
              </Layout>
            }
          />
          <Route
            path="/subscription"
            element={
              <Layout>
                <AllSubscrition />
              </Layout>
            }
          />
          <Route
            path="/view-all/:id"
            element={
              <Layout>
                <ViewAll />
              </Layout>
            }
          />
          <Route
            path="/return-policy"
            element={
              <Layout>
                <Return />
              </Layout>
            }
          />
          <Route
            path="/login"
            element={
              <Layout>
                <Login />
              </Layout>
            }
          />
          <Route
            path="/category/:slug/:id"
            element={
              <Layout active>
                <Category />
              </Layout>
            }
          />
          <Route
            path="/watchlist"
            element={
              <Layout>
                <WatchList />
              </Layout>
            }
          />
          <Route
            path="/rented"
            element={
              <Layout>
                <Rented />
              </Layout>
            }
          />
          <Route
            path="/"
            element={
              <Layout active>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/movie/:id"
            element={
              <Layout>
                <MovieDetails />
              </Layout>
            }
          />
          <Route
            path="/series/:slug"
            element={
              <Layout>
                <SeriesDetails />
              </Layout>
            }
          />
          <Route
            path="/my-account"
            element={
              <Layout>
                <Account />
              </Layout>
            }
          />
          <Route
            path="/my-account/rented"
            element={
              <Layout>
                <Rented />
              </Layout>
            }
          />
          <Route
            path="/termsandconditions"
            element={
              <Layout>
                <Terms />
              </Layout>
            }
          />
          <Route
            path="/privacyPolicy"
            element={
              <Layout>
                <Privacy />
              </Layout>
            }
          />
          <Route
            path="/about-us"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="/refund-policy"
            element={
              <Layout>
                <RefundPolicy />
              </Layout>
            }
          />
          <Route
            path="/search"
            element={
              <Layout>
                <SearchMobile />
              </Layout>
            }
          />
        </Routes>
      </div>
    </QueryClientProvider>
  );
}

export default App;

import Hls from "hls.js";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import { useEffect, useRef, useState } from "react";

const plyrOptions = { ratio: "16:9", autopause: true };

const VideoPlayer = ({
  selectorId,
  videoSource,
  hlsSource,
  poster,
  autoPlay = false,
}) => {
  const video = useRef(null);
  const [supported] = useState(Hls.isSupported());
  const hls = useRef(null);
  const player = useRef(null);

  useEffect(() => {
    video.current = document.getElementById(selectorId);

    if (poster && video.current) {
      //@ts-ignore
      video.current.poster = poster;
    }

    if (!supported || !hlsSource) {
      video.current.src = videoSource;
      player.current = new Plyr(video.current, plyrOptions);

      if (autoPlay) {
        player.current.play();
      }

      return () => {
        player.current?.destroy(() => console.log("player destroyed"));
      };
    }

    hls.current = new Hls({ maxMaxBufferLength: 10, autoStartLoad: false });
    hls.current.loadSource(hlsSource);
    hls.current.attachMedia(video.current);

    hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
      const levels = hls?.current?.levels || [];
      const quality = {
        default: levels[levels.length - 1].height,
        options: levels.map((level) => level.height),
        forced: true,
        onChange: (newQuality) => {
          levels.forEach((level, levelIndex) => {
            if (level.height === newQuality) {
              // @ts-ignore
              hls.current.currentLevel = levelIndex;
            }
          });
        },
      };

      player.current = new Plyr(video?.current, {
        ...plyrOptions,
        quality,
      });

      player?.current?.on("play", () => {
        hls?.current?.startLoad();
      });

      player?.current?.on("pause", () => {
        hls?.current?.stopLoad();
      });

      if (autoPlay) {
        player.current.play();
      }
    });

    return () => {
      hls.current?.destroy();
      player.current?.destroy();
    };
  }, [videoSource]);

  return (
    <div className="w-full">
      <video id={selectorId} className="w-full" />
    </div>
  );
};

export default VideoPlayer;

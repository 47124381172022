import React from "react";
import { useSearchParams } from "react-router-dom";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";

const SeriesEpisodes = ({
  seasons,
  selectedSeason,
  setSelectedSeason,
  setSearchParams,
  seasonEpisodes,
  selectedEpisode,
  handleEpisodeClick,
}) => {
  const [searchParams] = useSearchParams();
  const currentSeason = searchParams.get("season");
  return (
    <div className="space-y-8">
      {/* Seasons */}
      <div>
        <h3 className="mb-4 text-lg font-semibold text-white">Seasons</h3>
        <Swiper
          slidesPerView="auto"
          spaceBetween={16}
          navigation={true}
          modules={[Navigation]}
          className="season-swiper"
        >
          {seasons?.map((season, index) => (
            <SwiperSlide key={season._id} style={{ width: "auto" }}>
              <button
                onClick={() => {
                  setSelectedSeason(season);
                  setSearchParams((prev) => {
                    prev.set("season", index);
                    return prev;
                  });
                }}
                className={`rounded-lg px-6 py-2.5 text-sm transition-all ${
                  selectedSeason?._id === season._id
                    ? "bg-amber-500 text-white"
                    : "bg-primary-100/20 text-gray-300 hover:bg-primary-100/30"
                }`}
              >
                Season {index + 1}
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Episodes */}
      <div>
        <h3 className="mb-4 text-lg font-semibold text-white">Episodes</h3>
        <div className="flex flex-col gap-4">
          {seasonEpisodes?.map((ep, index) => (
            <div
              key={ep._id}
              onClick={() => handleEpisodeClick(ep)}
              className={`group flex cursor-pointer flex-col gap-3 rounded-lg bg-primary-100/10 p-4 transition-all hover:bg-primary-100/20 sm:flex-row ${
                selectedEpisode?._id === ep._id ? "ring-2 ring-amber-500" : ""
              }`}
            >
              <div className="relative aspect-video w-full overflow-hidden rounded sm:w-64">
                <img
                  src={ep.horizontalBanner}
                  alt={ep.name}
                  className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-sm font-semibold text-white">{ep.name}</h4>
                <p className="text-xs text-gray-400">{ep.description}</p>
                <div className="mt-2 flex flex-wrap items-center gap-4">
                  <span className="text-xs text-gray-400">
                    S{+currentSeason + 1} E{index + 1}
                  </span>
                  <span className="text-xs text-gray-400">
                    {ep.duration} min
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeriesEpisodes;

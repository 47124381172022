import { AnimatePresence, motion } from "framer-motion";
import {
  ChevronLeft,
  ChevronRight,
  PlayCircle,
  Star,
  Volume2,
  VolumeX,
} from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const BannerSwiper = ({ data = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [thumbnailStartIndex, setThumbnailStartIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const autoplayTimerRef = useRef(null);
  const filteredData = data?.filter((item) => item.isEnable) || [];
  const maxVisibleThumbnails = 5;

  const navigate = useNavigate();
  const handleNavigate = useCallback(
    (item) => {
      const type = item?.link?.kind === "movie" ? "movie" : "series";
      navigate(`/${type}/${item?.link?.item?.slug}`);
    },
    [navigate]
  );

  const goToSlide = useCallback(
    (index) => {
      if (isAnimating) return;

      setIsAnimating(true);
      clearInterval(autoplayTimerRef.current);

      const newIndex = (index + filteredData.length) % filteredData.length;
      setActiveIndex(newIndex);

      if (newIndex < thumbnailStartIndex) {
        setThumbnailStartIndex(newIndex);
      } else if (newIndex >= thumbnailStartIndex + maxVisibleThumbnails) {
        setThumbnailStartIndex(
          Math.max(0, newIndex - maxVisibleThumbnails + 1)
        );
      }

      setTimeout(() => {
        setIsAnimating(false);
        startAutoplay();
      }, 300);
    },
    [filteredData.length, isAnimating, thumbnailStartIndex]
  );

  const shiftThumbnails = (direction) => {
    const newIndex = thumbnailStartIndex + direction;
    if (
      newIndex >= 0 &&
      newIndex + maxVisibleThumbnails <= filteredData.length
    ) {
      setThumbnailStartIndex(newIndex);
    }
  };

  const startAutoplay = useCallback(() => {
    if (autoplayTimerRef.current) clearInterval(autoplayTimerRef.current);

    autoplayTimerRef.current = setInterval(() => {
      goToSlide(activeIndex + 1);
    }, 6000);
  }, [activeIndex, goToSlide]);

  useEffect(() => {
    startAutoplay();
    return () => clearInterval(autoplayTimerRef.current);
  }, [startAutoplay]);

  const BannerContent = ({ item }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.3 }}
      className="bottom-24  left-0 z-20 w-full max-w-4xl space-y-6 p-4 md:absolute md:p-16"
    >
      <div className="space-y-4 md:space-y-5">
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="inline-block rounded-full bg-cyan-500/30 px-4 py-1 text-sm font-semibold text-cyan-400 shadow-lg md:px-6 md:py-2 md:text-base"
        >
          New Release
        </motion.span>

        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-2xl font-bold text-white drop-shadow-[0_2px_4px_rgba(0,0,0,0.8)] md:text-4xl lg:text-5xl 2xl:text-7xl"
        >
          {item.link.item.name}
        </motion.h1>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="line-clamp-2 max-w-2xl overflow-hidden text-base font-medium text-white drop-shadow-lg md:text-lg"
        >
          {item.link.item.description}
        </motion.p>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex flex-wrap items-center gap-2 text-sm font-medium text-white drop-shadow-lg md:gap-4 md:text-base"
        >
          <span className="flex items-center gap-1">
            {item?.link?.item?.rating || "N/A"} <Star className="h-4 w-4" />
          </span>
          <span className="h-1.5 w-1.5 rounded-full bg-gray-300" />
          <span>{item?.link?.item?.ageLimit}</span>
          {item?.link?.kind !== "series" && (
            <>
              <span className="h-1.5 w-1.5 rounded-full bg-gray-300" />
              <span>{item.link.item.duration}</span>
            </>
          )}
          <span className="h-1.5 w-1.5 rounded-full bg-gray-300" />
          <span>{item.link.item.language}</span>
        </motion.div>

        <div className="flex items-center gap-3 pt-4 md:gap-5 md:pt-8">
          <button
            onClick={() => handleNavigate(item)}
            className="group flex items-center gap-2 rounded-xl bg-white px-6 py-3 text-base font-semibold text-black shadow-lg transition-all hover:bg-cyan-500 hover:text-white active:scale-95 md:gap-3 md:px-10 md:py-4 md:text-lg"
          >
            <PlayCircle className="h-5 w-5 transition-transform group-hover:scale-110 md:h-6 md:w-6" />
            Watch Now
          </button>
        </div>
      </div>
    </motion.div>
  );

  const visibleThumbnails = filteredData.slice(
    thumbnailStartIndex,
    thumbnailStartIndex + maxVisibleThumbnails
  );

  return (
    <div className="relative w-full overflow-hidden">
      <AnimatePresence initial={false} mode="wait">
        {filteredData.map(
          (item, index) =>
            index === activeIndex && (
              <motion.div
                key={item._id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="relative w-full md:h-[85vh]"
                onMouseEnter={() => {
                  setIsHovered(true);
                  clearInterval(autoplayTimerRef.current);
                }}
                onMouseLeave={() => {
                  setIsHovered(false);
                  startAutoplay();
                }}
              >
                <div className="from-25% absolute inset-0 z-10 hidden bg-gradient-to-r from-black via-black/10 to-transparent md:block" />
                <div className="absolute inset-0 z-10 hidden bg-gradient-to-b from-black/10 via-transparent to-black md:block" />

                {/* Video Player */}
                {isHovered && item.link.item.trailer && (
                  <div className="absolute inset-0 z-0 flex justify-end">
                    <div className="h-full w-[70%]">
                      <ReactPlayer
                        url={item.link.item.trailer}
                        playing={true}
                        muted={isMuted}
                        controls={false}
                        width="100%"
                        height="100%"
                        className="object-cover"
                        config={{
                          youtube: {
                            playerVars: { modestbranding: 1 },
                          },
                        }}
                      />
                    </div>
                  </div>
                )}

                {/* Background Image */}
                <img
                  src={item.link.item.horizontalBanner}
                  alt={item.link.item.name}
                  className={`aspect-video h-full object-cover transition-opacity duration-300 md:ml-auto ${
                    isHovered && item.link.item.trailer
                      ? "opacity-0"
                      : "opacity-100"
                  }`}
                  loading={index === 0 ? "eager" : "lazy"}
                />

                {/* Mute Button */}
                {isHovered && item.link.item.trailer && (
                  <motion.button
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsMuted(!isMuted)}
                    className="absolute right-4 top-4 z-30 rounded-full bg-black/50 p-2 text-white backdrop-blur-sm transition-all hover:bg-black/70"
                  >
                    {isMuted ? (
                      <VolumeX className="h-6 w-6" />
                    ) : (
                      <Volume2 className="h-6 w-6" />
                    )}
                  </motion.button>
                )}

                <BannerContent item={item} />
              </motion.div>
            )
        )}
      </AnimatePresence>

      <div className="right-0 mt-2 flex items-center gap-2 p-2 md:absolute md:bottom-7 md:z-30 md:gap-4 md:p-0">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => shiftThumbnails(-1)}
          disabled={thumbnailStartIndex === 0}
          className={`rounded-full bg-black/70 p-2 backdrop-blur-sm transition-all
            ${
              thumbnailStartIndex === 0
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-black/90"
            }`}
        >
          <ChevronLeft className="h-4 w-4 text-white md:h-5 md:w-5" />
        </motion.button>

        {visibleThumbnails.map((item, index) => (
          <motion.div
            key={item._id}
            onClick={() => goToSlide(thumbnailStartIndex + index)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`relative cursor-pointer overflow-hidden rounded-lg transition-all duration-300
              ${
                thumbnailStartIndex + index === activeIndex
                  ? "ring-2 ring-white ring-offset-2 ring-offset-black md:ring-4 md:ring-offset-4"
                  : "opacity-40 hover:opacity-70"
              }`}
          >
            <img
              src={item?.link?.item?.horizontalBanner}
              alt={item?.link?.item?.name}
              className="h-12 w-20 object-cover md:h-16 md:w-28"
              loading="eager"
            />
          </motion.div>
        ))}

        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => shiftThumbnails(1)}
          disabled={
            thumbnailStartIndex + maxVisibleThumbnails >= filteredData.length
          }
          className={`rounded-full bg-black/70 p-2 backdrop-blur-sm transition-all
            ${
              thumbnailStartIndex + maxVisibleThumbnails >= filteredData.length
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-black/90"
            }`}
        >
          <ChevronRight className="h-4 w-4 text-white md:h-5 md:w-5" />
        </motion.button>
      </div>
    </div>
  );
};

export default BannerSwiper;

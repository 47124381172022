import React from "react";

const SeriesSkeleton = () => {
  return (
    <div className="min-h-[100dvh] animate-pulse bg-black px-4 py-10 md:px-10">
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="w-full space-y-6 md:w-[70%]">
          {/* Video Player Skeleton */}
          <div className="relative aspect-video overflow-hidden rounded-xl bg-gray-700" />

          {/* Series Info Skeleton */}
          <div className="space-y-6">
            <div className="flex flex-col justify-between gap-4 rounded-xl bg-gray-700 p-4 md:flex-row md:items-center">
              <div className="h-8 w-48 rounded bg-gray-600" />
              <div className="flex gap-4">
                <div className="h-10 w-32 rounded bg-gray-600" />
                <div className="h-10 w-40 rounded bg-gray-600" />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="space-y-4">
                <div className="flex gap-3">
                  {[1, 2, 3].map((i) => (
                    <div
                      key={i}
                      className="h-6 w-20 rounded-full bg-gray-700"
                    />
                  ))}
                </div>
                <div className="h-4 w-32 rounded bg-gray-700" />
              </div>
              <div className="space-y-2">
                <div className="flex gap-1">
                  {[1, 2, 3, 4, 5].map((i) => (
                    <div key={i} className="h-6 w-6 rounded bg-gray-700" />
                  ))}
                </div>
                <div className="h-4 w-24 rounded bg-gray-700" />
              </div>
            </div>
          </div>

          {/* Episodes Skeleton */}
          <div className="space-y-8">
            <div>
              <div className="mb-4 h-6 w-24 rounded bg-gray-700" />
              <div className="flex gap-4 overflow-x-auto">
                {[1, 2, 3, 4].map((i) => (
                  <div key={i} className="h-10 w-32 rounded bg-gray-700" />
                ))}
              </div>
            </div>

            <div>
              <div className="mb-4 h-6 w-24 rounded bg-gray-700" />
              <div className="flex flex-col gap-4">
                {[1, 2, 3].map((i) => (
                  <div
                    key={i}
                    className="flex flex-col gap-3 rounded-lg bg-gray-700 p-4 sm:flex-row"
                  >
                    <div className="aspect-video w-full rounded bg-gray-600 sm:w-64" />
                    <div className="flex flex-col gap-2">
                      <div className="h-4 w-48 rounded bg-gray-600" />
                      <div className="h-16 w-full rounded bg-gray-600" />
                      <div className="mt-2 flex items-center gap-4">
                        <div className="h-3 w-16 rounded bg-gray-600" />
                        <div className="h-3 w-16 rounded bg-gray-600" />
                        <div className="flex gap-1">
                          {[1, 2, 3, 4, 5].map((j) => (
                            <div
                              key={j}
                              className="h-3 w-3 rounded bg-gray-600"
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Similar Series Skeleton */}
        <div className="hidden w-full md:block md:w-[30%]">
          <div className="mb-4 h-6 w-32 rounded bg-gray-700" />
          <div className="grid grid-cols-2 gap-4">
            {[1, 2, 3, 4].map((i) => (
              <div
                key={i}
                className="aspect-[2/3] w-full rounded bg-gray-700"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeriesSkeleton;

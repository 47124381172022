import useEmblaCarousel from "embla-carousel-react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PictureCard from "./PictureCard";

const VideoCard = ({ data, className }) => {
  const navigate = useNavigate();
  const videoCards =
    data?.title === "Trending Now"
      ? {
          ...data,
          imageType: "Vertical",
        }
      : data;
  const [selectedCard, setSelectedCard] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const cardRefs = useRef([]);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    dragFree: true,
    skipSnaps: true,
  });
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [isHoveringCarousel, setIsHoveringCarousel] = useState(false);

  useEffect(() => {
    cardRefs.current = videoCards?.data?.map(() => React.createRef());

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [videoCards?.data]);

  const handleMouseMove = useCallback(
    (event, index) => {
      if (!isHovered) return;
      const cardRef = cardRefs?.current?.[index];
      if (!cardRef?.current) return;

      const rect = cardRef.current.getBoundingClientRect();
      setMousePosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      });
    },
    [isHovered]
  );

  const handleCardClick = useCallback(
    (item, index) => {
      navigate(
        `/${item?.type === "Single" ? "movie" : "series"}/${item?.slug}`
      );
    },
    [navigate]
  );

  const handleMouseEnter = (index) => {
    setSelectedCard(index);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setSelectedCard(null);
    setIsHovered(false);
    setMousePosition({ x: 0, y: 0 });
  };

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setIsStart(emblaApi.canScrollPrev());
    setIsEnd(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect();
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);

    return () => {
      emblaApi.off("select", onSelect);
      emblaApi.off("reInit", onSelect);
    };
  }, [emblaApi, onSelect]);

  const getSlidesPerView = () => {
    if (videoCards?.imageType === "Vertical") {
      if (windowWidth >= 1600) return 7;
      if (windowWidth >= 1440) return 6;
      if (windowWidth >= 1280) return 5;
      if (windowWidth >= 1024) return 4;
      if (windowWidth >= 768) return 3;
      if (windowWidth >= 640) return 2;

      return 2;
    } else {
      if (windowWidth >= 1600) return 5;
      if (windowWidth >= 1440) return 4;
      if (windowWidth >= 1280) return 3;
      if (windowWidth >= 1024) return 2;
      if (windowWidth >= 768) return 2;
      if (windowWidth >= 640) return 1.7;
      return 1.4;
    }
  };

  const slideWidth = `${100 / getSlidesPerView()}%`;

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
      onSelect();
    }
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
      onSelect();
    }
  }, [emblaApi]);

  console.log(videoCards);

  return (
    <div className="relative overflow-hidden pb-10">
      {/* className */}
      <div
        className={`flex items-center justify-between  ${
          className ? className : "px-4 md:px-10"
        }`}
      >
        <h1 className="text-lg font-semibold text-white md:text-xl">
          {videoCards?.name}
        </h1>
        <button
          onClick={() =>
            navigate(`/view-all/${videoCards?.name}`, { state: videoCards })
          }
          className="group flex items-center gap-2 text-sm font-semibold text-white/90 transition-colors hover:text-primary-100"
        >
          View All
          <ChevronRight className="h-4 w-4 transition-transform group-hover:translate-x-1" />
        </button>
      </div>

      <div
        className={`relative ${className ? className : "px-4 md:px-10"}`}
        onMouseEnter={() => setIsHoveringCarousel(true)}
        onMouseLeave={() => setIsHoveringCarousel(false)}
      >
        {/* Left Arrow with Gradient */}
        {isStart && isHoveringCarousel && (
          <button
            onClick={scrollPrev}
            className="absolute left-0 top-0 z-10  flex h-full w-10 items-center justify-center bg-gradient-to-r from-bg via-bg/50 to-transparent opacity-100 transition-opacity duration-300 hover:opacity-80 "
          >
            <ChevronLeft className="h-10 w-10 text-white" />
          </button>
        )}

        {/* Right Arrow with Gradient */}
        {isEnd && isHoveringCarousel && (
          <button
            onClick={scrollNext}
            className="absolute right-0 top-0 z-10 flex h-full w-12 items-center justify-center bg-gradient-to-l from-bg via-bg/50 to-transparent opacity-100 transition-opacity duration-300 hover:opacity-80"
          >
            <ChevronRight className="h-10 w-10 text-white" />
          </button>
        )}

        <div className="embla overflow-hidden" ref={emblaRef}>
          <div
            className={`embla__container flex ${
              videoCards?.name === "Trending Now" ? "gap-20 pl-10" : ""
            }`}
          >
            {videoCards?.content?.map((item, index) => (
              <div
                key={index}
                className="embla__slide min-w-0  "
                style={{ flex: `0 0 ${slideWidth}` }}
              >
                <div className="relative select-none">
                  {videoCards?.name === "Trending Now" && (
                    <div className="pointer-events-none absolute -left-1 top-0 z-10 h-full w-full bg-gradient-to-r from-bg via-bg/5 to-transparent" />
                  )}
                  {videoCards?.name === "Trending Now" && (
                    <div
                      style={{
                        textShadow: "0 0 1.5rem rgba(0,0,0,0.5)",
                      }}
                      className="absolute -bottom-10 -left-[3rem] z-10 select-none text-[10rem] font-bold text-white "
                    >
                      {index + 1}
                    </div>
                  )}
                  <PictureCard
                    item={item?.item}
                    index={index}
                    imageType={videoCards?.imageType}
                    isSelected={selectedCard === index}
                    onMouseMove={handleMouseMove}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleCardClick}
                    cardRef={cardRefs?.current?.[index]}
                    mousePosition={mousePosition}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
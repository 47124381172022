import React from "react";

const CastSection = ({ cast }) => {
  if (!cast?.length) return null;

  return (
    <div className="py-8">
      <h2 className="mb-6 text-lg font-semibold text-white">Cast</h2>
      <div className="grid grid-cols-2 gap-6 md:grid-cols-4 lg:grid-cols-6">
        {cast.map((item) => (
          <div key={item._id} className="group">
            <div className="relative overflow-hidden rounded-lg">
              <img
                src={item.image}
                alt={item.name}
                className="aspect-[2/3] w-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
            </div>
            <div className="mt-2 text-center">
              <p className="font-medium text-white">{item.name}</p>
              <p className="text-sm text-gray-400">{item.role}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CastSection;

import React from "react";
import { BsFillPlayFill } from "react-icons/bs";
import ReactPlayer from "react-player/youtube";
import VideoPlayer from "./VideoPlayer";

const MediaPlayer = ({ media, access, time = 0, onPlay }) => {
  if ((!media?.trailer || media.trailer === "undefined") && !media?.url) {
    return (
      <div className="group relative h-full cursor-pointer" onClick={onPlay}>
        <img
          src={media?.horizontalBanner}
          alt={media?.name}
          className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black/40 opacity-0 transition-opacity group-hover:opacity-100">
          <BsFillPlayFill className="text-6xl text-white" />
        </div>
      </div>
    );
  }

  if (access) {
    if (media?.url?.includes("www.youtube.com")) {
      return (
        <ReactPlayer
          url={media?.url}
          controls={true}
          playing={true}
          width="100%"
          height="100%"
          className="overflow-hidden rounded-xl"
        />
      );
    }
    return (
      <VideoPlayer
        id={media?._id}
        hlsSource={media?.url}
        startTime={time}
        autoPlay={false}
        poster={media?.horizontalBanner}
        videoSource={media?.url}
        selectorId={"video"}
        subtitles={
          media?.subtitles?.map((subtitle) => ({
            title: subtitle.title || subtitle.language || "English",
            language: subtitle.language || "en",
            src: subtitle.link,
          })) || []
        }
      />
    );
  }

  return media?.trailer?.includes("www.youtube.com") ? (
    <ReactPlayer
      url={media?.trailer}
      controls={true}
      playing={true}
      width="100%"
      height="100%"
      className="overflow-hidden rounded-xl"
    />
  ) : (
    <VideoPlayer
      hlsSource={media?.trailer}
      autoPlay={false}
      poster={media?.horizontalBanner}
    />
  );
};

export default MediaPlayer;

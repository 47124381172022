import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MovieModal from "../components/MovieModal";
import SeriesEpisodes from "../components/Series/SeriesEpisodes";
import SeriesInfo from "../components/Series/SeriesInfo";
import SeriesPlayer from "../components/Series/SeriesPlayer";
import SeriesSkeleton from "../components/Series/SeriesSkeleton";
import PictureCard from "../components/VideoCards/PictureCard";
import VideoCard from "../components/VideoCards/VideoCard";
import VideoPlayer from "../components/VideoCards/VideoPlayer";
import {
  useEpisodeDetails,
  useSeasonEpisodes,
  useSeriesDetails,
} from "../hooks/useSeriesDetails";
import {
  addSeriesView,
  addToWatchList,
  checkUserWatchlist,
  removeFromWatchList,
  rentASeries,
} from "../utils/apis";
import useStore from "../utils/store";

const SeriesDetails = () => {
  const { slug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSeason, setSelectedSeason] = React.useState(null);
  const [selectedEpisode, setSelectedEpisode] = React.useState(null);
  const [watch, setWatch] = React.useState(false);
  const [activeVideo, setActiveVideo] = React.useState(null);
  const [hasTrackedView, setHasTrackedView] = React.useState(false);
  const episodePlayerRef = React.useRef(null);
  const trailerPlayerRef = React.useRef(null);
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const tabs = ["Episodes", "More Like This", "Trailers & More", "Extras"];
  const [activeTab, setActiveTab] = React.useState(tabs[0]);
  const currentSeason = searchParams.get("season");
  const currentEpisode = searchParams.get("episode");

  const {
    data: seriesData,
    isLoading: seriesLoading,
    refetch: seriesRefetch,
  } = useSeriesDetails(slug, user?.token);

  const { data: seasonEpisodes, isLoading: episodesLoading } =
    useSeasonEpisodes(selectedSeason?.slug, user?.token);

  const { data: episodeDetails, isLoading: episodeLoading } = useEpisodeDetails(
    selectedEpisode?.slug,
    user?.token
  );

  const { data: watchlistData } = useQuery({
    queryKey: ["watchlist", seriesData?.series?._id],
    queryFn: () => checkUserWatchlist(user?.token, seriesData?.series?._id),
    enabled: !!user?.token && !!seriesData?.series?._id,
    select: (data) => data[1]?.isExist,
  });

  const watchlistMutation = useMutation({
    mutationFn: ({ isAdding }) =>
      isAdding
        ? addToWatchList(user.token, {
            id: seriesData.series._id,
            type: "series",
          })
        : removeFromWatchList(user.token, {
            id: seriesData.series._id,
            type: "series",
          }),
    onSuccess: () => {
      queryClient.invalidateQueries(["watchlist", seriesData?.series?._id]);
    },
  });

  const rentMutation = useMutation({
    mutationFn: () =>
      rentASeries(user?.token, {
        type: "Series",
        seriesId: seriesData?.series?._id,
      }),
    onSuccess: () => {
      setWatch(false);
      seriesRefetch();
    },
  });

  const [showCountdown, setShowCountdown] = React.useState(false);
  const [countdownValue, setCountdownValue] = React.useState(5);
  const [nextEpisode, setNextEpisode] = React.useState(null);
  const [nextSeason, setNextSeason] = React.useState(null);
  const nextEpisodeTimerRef = React.useRef(null);
  const countdownIntervalRef = React.useRef(null);

  React.useEffect(() => {
    if (seriesData?.seasons?.length > 0) {
      let season;
      if (currentSeason) {
        season = seriesData?.seasons?.[currentSeason];
      }

      season = season || seriesData?.seasons?.[0];
      setSelectedSeason(season);

      if (!currentSeason) {
        setSearchParams((prev) => {
          prev.set("season", 0);
          return prev;
        });
      }
    }
  }, [seriesData, currentSeason]);

  React.useEffect(() => {
    if (seasonEpisodes?.length > 0) {
      let episode;
      if (currentEpisode) {
        episode = seasonEpisodes?.find(
          (ep) => ep?.slug?.toString() === currentEpisode
        );
      }
      episode = episode || seasonEpisodes?.[0];
      setSelectedEpisode(episode);

      if (!currentEpisode || episode?.slug?.toString() !== currentEpisode) {
        setSearchParams((prev) => {
          prev.set(
            "episode",
            seriesData?.lastWatchedEpisode ?? episode?.slug?.toString()
          );
          return prev;
        });
      }
    }
  }, [seasonEpisodes, currentEpisode]);

  // Track view when component mounts and series data is loaded
  React.useEffect(() => {
    if (seriesData?.series?._id && user?.token && !hasTrackedView) {
      addSeriesView(user.token, seriesData.series._id);
      setHasTrackedView(true);
    }
  }, [seriesData?.series?._id, user?.token, hasTrackedView]);

  const handleWatchlist = (isAdding) => {
    if (!user?.token || !seriesData?.series?._id) return;
    watchlistMutation.mutate({ isAdding });
  };

  const handleWatchClick = () => {
    if (!user) {
      navigate("/login");
      return;
    }

    if (episodeDetails?.oldSubscriptionValid) {
      setWatch(true);
    } else {
      navigate("/subscription");
    }
  };

  const handleEpisodeClick = (ep) => {
    if (!user) {
      navigate("/login");
      return;
    }
    if (!episodeDetails?.access) {
      navigate("/subscription");
      return;
    }
    setSelectedEpisode(ep);
    setSearchParams((prev) => {
      prev.set("episode", ep?.slug?.toString());
      return prev;
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleEpisodeEnd = () => {
    if (!seasonEpisodes || seasonEpisodes.length === 0 || !selectedEpisode)
      return;

    // Find current episode index
    const currentIndex = seasonEpisodes.findIndex(
      (ep) => ep._id === selectedEpisode._id
    );

    // If there's a next episode in current season
    if (currentIndex >= 0 && currentIndex < seasonEpisodes.length - 1) {
      const nextEp = seasonEpisodes[currentIndex + 1];
      setNextEpisode(nextEp);
      setShowCountdown(true);
      setCountdownValue(3); // Start with 3 seconds

      // Start countdown
      countdownIntervalRef.current = setInterval(() => {
        setCountdownValue((prev) => {
          if (prev <= 1) {
            clearInterval(countdownIntervalRef.current);
            // Auto-play next episode
            handleEpisodeClick(nextEp);
            setShowCountdown(false);
            setNextEpisode(null);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    // If this is the last episode of the season and there are more seasons
    else if (
      currentIndex === seasonEpisodes.length - 1 &&
      seasons?.length > 0
    ) {
      const currentSeasonIndex = seasons.findIndex(
        (s) => s._id === selectedSeason?._id
      );

      // If there's a next season
      if (currentSeasonIndex >= 0 && currentSeasonIndex < seasons.length - 1) {
        const nextSeason = seasons[currentSeasonIndex + 1];
        setNextSeason(nextSeason);
        setShowCountdown(true);
        setCountdownValue(3); // Start with 3 seconds

        // Start countdown
        countdownIntervalRef.current = setInterval(() => {
          setCountdownValue((prev) => {
            if (prev <= 1) {
              clearInterval(countdownIntervalRef.current);
              // Auto-switch to next season
              setSelectedSeason(nextSeason);
              setSearchParams((prev) => {
                prev.set("season", nextSeason.position.toString());
                return prev;
              });
              setShowCountdown(false);
              setNextSeason(null);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
    }
  };

  // Clean up countdown timer when component unmounts
  React.useEffect(() => {
    return () => {
      clearInterval(countdownIntervalRef.current);
    };
  }, []);

  // Handle skip button click
  const handleSkipClick = () => {
    clearInterval(countdownIntervalRef.current);

    if (nextEpisode) {
      handleEpisodeClick(nextEpisode);
    } else if (nextSeason) {
      setSelectedSeason(nextSeason);
      setSearchParams((prev) => {
        prev.set("season", nextSeason.position.toString());
        return prev;
      });
    }

    setShowCountdown(false);
    setNextEpisode(null);
    setNextSeason(null);
  };

  // Handle cancel button click
  const handleCancelAutoplay = () => {
    clearInterval(countdownIntervalRef.current);
    setShowCountdown(false);
    setNextEpisode(null);
    setNextSeason(null);
  };

  if (seriesLoading) {
    return <SeriesSkeleton />;
  }

  const { series, seasons, similarSeries } = seriesData || {};
  const { access } = episodeDetails || {};

  return (
    <div className="min-h-[100dvh] bg-black px-4 py-10 md:px-10">
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="w-full space-y-6 md:w-[70%]">
          <div className="relative aspect-video overflow-hidden rounded-xl shadow-2xl">
            <SeriesPlayer
              access={access}
              series={series}
              episode={episodeDetails?.episode}
              onPlay={() => {
                setActiveVideo("episode");
                if (trailerPlayerRef.current) {
                  try {
                    trailerPlayerRef.current.stop();
                  } catch (error) {
                    console.error("Error stopping trailer:", error);
                  }
                }
              }}
              onEnded={handleEpisodeEnd}
              playerRef={episodePlayerRef}
            />

            {/* Next Episode Countdown Overlay */}
            {showCountdown && (
              <div className="absolute inset-0 z-50 flex items-center justify-center bg-black/70">
                <div className="w-[80%] max-w-md rounded-xl bg-gray-900 p-6 shadow-lg">
                  <div className="mb-4 text-center">
                    <p className="text-sm font-medium text-gray-400">
                      Playing next in
                    </p>
                    <div className="my-2 text-4xl font-bold text-amber-500">
                      {countdownValue}
                    </div>
                    <h3 className="text-xl font-bold text-white">
                      {nextEpisode
                        ? `Next: ${nextEpisode.name}`
                        : `Season ${nextSeason?.position}`}
                    </h3>
                  </div>
                  <div className="flex justify-center space-x-4">
                    <button
                      onClick={handleSkipClick}
                      className="rounded bg-amber-500 px-5 py-2 font-medium text-black transition-colors hover:bg-amber-600"
                    >
                      Play Now
                    </button>
                    <button
                      onClick={handleCancelAutoplay}
                      className="rounded border border-gray-600 bg-transparent px-5 py-2 font-medium text-white transition-colors hover:bg-gray-800"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <SeriesInfo
            series={series}
            selectedSeason={selectedSeason}
            selectedEpisode={selectedEpisode}
            access={access}
            handleWatchClick={handleWatchClick}
            handleWatchlist={handleWatchlist}
            watchlistData={watchlistData}
            watchlistMutation={watchlistMutation}
            user={user}
          />

          <div className="border-b border-secondary3">
            <div className="no-scrollbar flex gap-4 overflow-x-auto py-4 sm:gap-8">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`whitespace-nowrap text-base sm:text-lg ${
                    activeTab === tab ? "text-white" : "text-gray-400"
                  }`}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>

          {activeTab === "Episodes" && (
            <SeriesEpisodes
              seasons={seasons}
              selectedSeason={selectedSeason}
              setSelectedSeason={setSelectedSeason}
              setSearchParams={setSearchParams}
              seasonEpisodes={seasonEpisodes}
              selectedEpisode={selectedEpisode}
              handleEpisodeClick={handleEpisodeClick}
            />
          )}

          {activeTab === "More Like This" && (
            <div>
              {similarSeries?.length > 0 && (
                <VideoCard
                  className={"px-0"}
                  data={{
                    title: "Similar Series",
                    imageType: "Vertical",
                    data: similarSeries,
                  }}
                />
              )}
            </div>
          )}
          {activeTab === "Trailers & More" && (
            <div className="max-w-3xl">
              <h3 className="mb-4 text-lg font-semibold text-white">
                Official Trailer
              </h3>
              <div className="aspect-video overflow-hidden rounded-xl">
                {series?.trailer ? (
                  series.trailerType === "Video" ? (
                    <VideoPlayer
                      videoSource={series.trailer}
                      autoPlay={false}
                      poster={series.horizontalBanner}
                      selectorId="trailer-video"
                      onPlay={() => {
                        setActiveVideo("trailer");
                        if (episodePlayerRef.current) {
                          try {
                            episodePlayerRef.current.stop();
                          } catch (error) {
                            console.error("Error stopping episode:", error);
                          }
                        }
                      }}
                      playerRef={trailerPlayerRef}
                    />
                  ) : (
                    <ReactPlayer
                      url={series.trailer}
                      controls={true}
                      width="100%"
                      height="100%"
                      onPlay={() => {
                        setActiveVideo("trailer");
                        if (episodePlayerRef.current) {
                          try {
                            episodePlayerRef.current.stop();
                          } catch (error) {
                            console.error("Error stopping episode:", error);
                          }
                        }
                      }}
                      ref={trailerPlayerRef}
                    />
                  )
                ) : (
                  <div className="flex h-full items-center justify-center">
                    <p className="text-gray-400">No trailer available</p>
                  </div>
                )}
              </div>
            </div>
          )}

          {activeTab === "Extras" && (
            <div className="space-y-8">
              {/* Cast Section */}
              {series?.cast?.length > 0 && (
                <div>
                  <h3 className="mb-6 text-lg font-semibold text-white">
                    Cast
                  </h3>
                  <div className="grid grid-cols-2 gap-6 md:grid-cols-4 lg:grid-cols-6">
                    {series.cast?.map((item) => (
                      <div key={item._id} className="group">
                        <div className="relative overflow-hidden rounded-lg">
                          <img
                            src={item.image}
                            alt={item.name}
                            className="aspect-[2/3] w-full object-cover transition-transform duration-300 group-hover:scale-105"
                          />
                        </div>
                        <div className="mt-2 text-center">
                          <p className="font-medium text-white">{item.name}</p>
                          <p className="text-sm text-gray-400">{item.role}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Similar Series Section */}
        <div className="hidden w-full md:block md:w-[30%]">
          <h2 className="mb-4 text-lg font-semibold text-white">
            Similar Series
          </h2>
          <div className="grid grid-cols-2 gap-4">
            {similarSeries?.map((item) => (
              <div
                key={item._id}
                className="transform transition-transform hover:scale-105"
              >
                <PictureCard
                  imageType="Vertical"
                  item={item}
                  onClick={() => {
                    navigate(`/series/${item?.slug}`);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {watch && (
        <MovieModal
          data={series}
          setWatch={setWatch}
          RentHandler={() => rentMutation.mutate()}
          access={access}
        />
      )}
    </div>
  );
};

export default SeriesDetails;
